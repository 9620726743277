import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

class Resume extends Component {
  render() {

    if(this.props.data){
      var education = this.props.data.education.map(function(education){
        return <div key={education.school}><h3>{education.school}</h3>
        <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
        <p>{education.description}</p></div>
      })
      var work = this.props.data.work.map(function(work){
        return (
        <div key={work.company}>
          <h3>{work.title}</h3>
          <p className="info">{work.company}<span>&bull;</span> <em className="date">{work.years}</em></p>
          <p>{work.description}</p>
        </div>
        )
      })
      var skills = this.props.data.skills.map(function(skills){

        return (
          <div key={skills.name}>
            <h3>{skills.name}</h3>
            <p className="info">{skills.description}</p>
            <br/>
          </div>
        )

      })
    }

    return (
      <section id="resume">

      <Fade bottom cascade>
      <div className="row education"> 
         <div className="three columns header-col">
            <h1><span>Education</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 {education}
               </div>
            </div>
         </div>
      </div>
      </Fade>


      <div className="row work">
      <Fade bottom cascade>
        <div className="three columns header-col">
            <h1><span>Work</span></h1>
        </div>

        <div className="nine columns main-col">
          {work}
        </div>
      </Fade>
      </div>



      <div className="row skills">
        <Fade bottom cascade>
          <div className="three columns header-col">
              <h1><span>Skills</span></h1>
          </div>

          <div className="nine columns main-col">
            {skills}
          </div>
        </Fade>
      </div>
   </section>
    );
  }
}

export default Resume;
